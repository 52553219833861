import React from 'react';

import PostItem from '#/components/post-item';

export default function IndexPage(props) {
  const {
    pageContext: {
      title,
      description,
    },
    data: {
      allMarkdownRemark: {
        edges,
      },
    },
  } = props;
  return (
    <main className="index-page">
      <section className="tw-my-4">
        <h1>{title}</h1>
        {!!description && <div className="index-page-desc">{description}</div>}
      </section>
      <section>
        {edges.map((item, i) => (
          <PostItem key={i} data={item} />
        ))}
      </section>
    </main>
  );
}

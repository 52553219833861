import React from 'react';
import { Link } from 'gatsby';
import { format } from 'date-fns';

export default function PostItem(props) {
  const {
    data: {
      node: {
        frontmatter: {
          title, date: dateStr, description,
        },
        fields: {
          slug,
        },
      },
    },
  } = props;
  const date = new Date(dateStr);
  return (
    <div>
      <h2>
        <Link to={slug}>{title}</Link>
      </h2>
      <div className="tw-mb-1">
        <time dateTime={format(date, 'MMMM d, yyyy')}>
          {format(date, 'MMMM d, yyyy')}
        </time>
      </div>
      <p className="post-item">{description}</p>
    </div>
  );
}
